import { memo } from 'react';
import { FillLayer, Layer, Source, useMap } from 'react-map-gl';
import { useLayerState, usePlots, useTableState } from '../../hooks';
import { hasPrecisionAnalysis } from '../../helper';

const precisionLayerStyle: FillLayer = {
  id: 'precision-polygon',
  type: 'fill',
  source: 'precision',
  paint: {
    'fill-opacity': 0.4,
    'fill-color': '#D4111B',
  },
};

export const PrecisionAnalysisLayer = memo(() => {
  const { isLoading, findPlotById } = usePlots();
  const { tableState } = useTableState();
  const { layerState } = useLayerState();
  const { deforestationMap: map } = useMap();
  const plot = findPlotById(tableState.sel || '');
  const hasPrecision = hasPrecisionAnalysis(plot);

  return hasPrecision && !isLoading && map?.getMap() ? (
    <Source
      id="precision"
      type="geojson"
      data={plot?.mostRecentAnalysesByType?.PRECISION?.deforestedGeometry || {}}
    >
      <Layer
        {...precisionLayerStyle}
        layout={{
          visibility: hasPrecision && layerState.precision ? 'visible' : 'none',
        }}
      />
    </Source>
  ) : null;
});
