import { CustomSVGProps } from './CustomSVG.interface';

export const Directions: React.FC<CustomSVGProps> = ({
  color = 'none',
  style,
  ...props
}) => {
  return (
    <svg
      width={style?.fontSize}
      height={style?.fontSize}
      style={style}
      fill="none"
      color={color}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.9389 11.4611L16.9389 9.54106L14.9389 7.62106M8.93887 14.3411V11.2865C8.93887 10.8236 9.12325 10.3796 9.45144 10.0523C9.77962 9.72496 10.2247 9.54106 10.6889 9.54106H15.9389M13.1183 2.93429L20.944 10.4469C21.5614 11.0396 21.5614 12.0006 20.944 12.5934L13.1183 20.106C12.5009 20.6987 11.4999 20.6987 10.8824 20.106L3.05682 12.5934C2.43939 12.0006 2.43939 11.0396 3.05682 10.4469L10.8824 2.93429C11.4999 2.34156 12.5009 2.34156 13.1183 2.93429Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
