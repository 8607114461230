import { Geometry, bbox, area, convertArea } from '@turf/turf';
import { PLOT_LIMIT_SIZE_HA } from '../common/constants';

export const getBbox = (geometry: Geometry) => {
  const _bbox = bbox({
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry,
      },
    ],
  });

  if (_bbox?.length) {
    return [
      [_bbox[0], _bbox[1]],
      [_bbox[2], _bbox[3]],
    ];
  }
  return [];
};

export const convertAreaToHectares = (geometry: Geometry) =>
  convertArea(area(geometry), 'meters', 'hectares');

export const isPlotTooLargeToProcess = (geometry: Geometry) =>
  convertAreaToHectares(geometry) > PLOT_LIMIT_SIZE_HA ? true : false;
